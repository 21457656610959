import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpdatesService } from '@shared/services/updates.service';
import { signupEnvironment } from '@signup/environment/environment.signup';
import { EnvironmentsService } from '@signup/shared/services/environments.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _environments: EnvironmentsService,
    private readonly _translate: TranslateService,
    private readonly _updates: UpdatesService
  ) {
    const lng = 'en';
    this._translate.setDefaultLang(lng);
    this._translate.use(lng);

    // (window as any)['Chargebee'].init({
    //   site: 'vitay-test',
    //   publishableKey: signupEnvironment.CHARGEBEE.PUBLISHABLE_KEY
    // });

    this._updates.checkUpdates();
    this._environments.setScripts();
  }

  ngOnInit(): void {}
}
