import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@environment/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AirbrakeErrorHandler } from '@shared/handlers/airbrake-error.handler';
import { InterceptorService } from '@shared/services/interceptor.service';
import { CustomPreloadingStrategy } from '@shared/strategies/custom.preloading.strategy';
import { CoreRoutingModule } from '@signup/core/core-routing.module';
import { signupEnvironment } from '@signup/environment/environment.signup';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@Injectable({
  providedIn: 'root'
})
@NgModule({
  imports: [
    CoreRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RecaptchaV3Module,
    CarouselModule.forRoot(),
    NgxStripeModule.forRoot(signupEnvironment.STRIPE.PUBLISHABLE_KEY),
    ToastrModule.forRoot({
      timeOut: 2000,
      toastClass: 'ngx-toastr v-toastr',
      preventDuplicates: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      // serverLoggingUrl: '/logs',
      level: environment.IS_PRODUCTION
        ? NgxLoggerLevel.ERROR
        : NgxLoggerLevel.DEBUG
      // serverLogLevel: NgxLoggerLevel.ERROR
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'vitay.io'
    })
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [
    CustomPreloadingStrategy,
    CookieService,
    {
      provide: ErrorHandler,
      useClass: AirbrakeErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: signupEnvironment.RECAPTCHA.SITE_KEY
    }
  ]
})
export class CoreModule {}
