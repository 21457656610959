export const signupEnvironment = {
  STRIPE: {
    PUBLISHABLE_KEY: 'pk_test_NiGtkBKV9pSRLLtjExE2VzkU',
    CARD: {
      name: 'Lorem Ipsum',
      number: 4242424242424242,
      cvc: 123,
      exp_month: 10,
      exp_year: 20
    },
    PLAN_ID: 'price_1IEOjkCVlfSkYFv86a32snTq'
  },
  RECAPTCHA: {
    SITE_KEY: '6LdKwZcjAAAAAPD-IZv_SpqKxO517EPdaCMTh4Zr'
  },
  GOOGLE_ANALYTICS: {
    domain: '',
    GTM: ''
  }
};
