import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {
  public ENDPOINTS_ASSETS: string = environment.ENDPOINTS.ASSETS;
  public logo = String(
    `${this.ENDPOINTS_ASSETS}/images/vitay.io/logo-green.svg`
  );
  public readonly tokenName: string = String('recruiter-token');
  constructor(@Inject(DOCUMENT) private readonly _document: any) {}

  public setScripts() {
    if (environment.IS_PRODUCTION) {
      const head = this._document.getElementsByTagName('head')[0];
      const body = this._document.getElementsByTagName('body')[0];

      const scriptGATag = this._document.createElement('script');
      scriptGATag.scriptGATag = 'text/javascript';

      let gaTagInnerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});`;
      gaTagInnerHTML += `var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;`;
      gaTagInnerHTML += `j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})`;
      gaTagInnerHTML += `(window, document, 'script', 'dataLayer', '${environment.GOOGLE_ANALYTICS.GTM}');`;

      let helpScoutTag = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;
      helpScoutTag += `window.Beacon('init', '2dd0e0c9-b62d-45d2-8811-e3f5fa17612d')`;
      gaTagInnerHTML += helpScoutTag;

      scriptGATag.innerHTML = gaTagInnerHTML;

      const noScriptGATag = this._document.createElement('noscript');
      noScriptGATag.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GOOGLE_ANALYTICS.GTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

      body.appendChild(noScriptGATag);
      head.appendChild(scriptGATag);
    }
  }
}
